import React from 'react';
import ctc_graphs from '../../images/ctc_graphs.png'
import docs_added from '../../images/docs_added.png'
import how_you_compare_sentence from '../../images/how_you_compare_sentence.png'
import profile from '../../images/profile.png'
import same_native_city_graph from '../../images/same_native_city_graph.png'
import same_work_city from '../../images/same_work_city.png'
import sample_data from '../../images/sample_data.png'
import similarity_cities from '../../images/similarity_cities.png'
import similarity_company from '../../images/similarity_company.png'
import similarity_ctc from '../../images/similarity_ctc.png'
import similarity_skill_designation from '../../images/similarity_skill_designation.png'
import success_upload from '../../images/success_upload.png'
import top_nav_authenticated from '../../images/top_nav_authenticated.png'
import top_nav_dashboard_highlight from '../../images/top_nav_dashboard_highlight.png'
import welcome_msg from '../../images/welcome_msg.png'
import {Grid, Typography, Container, Divider} from '@material-ui/core'

///Users/maisha/Documents/Development/LinkoutDir/linkout2.0/public/images/ctc_graphs.png
const Services = () => {
  return (
    <div className="services-page">
     
    <Typography variant='h6'>We offer various insights into your professional profile based on our data under :</Typography>   
    <Grid item>
         <img src={how_you_compare_sentence} width="500px"/>
        </Grid>
      <Divider />
      <Grid container>

        
        <Grid item>
        <Typography variant='body2'>A comparision of your CTC as compared to others :</Typography>
          <img src={similarity_ctc} width="500px"/>
        </Grid>

        <Grid item>
        <Typography variant='body2'>A comparision of companies your worked for as compared to others :</Typography>
          <img src={similarity_company} width="500px"/>
        </Grid>
        
        
        <Grid item>
        <Typography variant='body2'>A comparision of your skills and designation as compared to others :</Typography>
          <img src={similarity_skill_designation} width="500px"/>
        </Grid>


        <Grid item>
        <Typography variant='body2'>A comparision of cities your worked or stayed in as compared to others :</Typography>
           <img src={similarity_cities} width="500px"/>
        </Grid>

        <Grid item>
        <Typography variant='body2'> Personalized graphs that tell you about percent CTC changes and absolute changes :</Typography>
          <img src={ctc_graphs} width="500px"/>
        </Grid>
        
        <Divider />
        <Typography variant='h6'>We also have information about how your comparitive stats changed over time</Typography>   
        <Divider />
        <Grid item>
        <Typography variant='body2'>Number of people over time that have worked in same city as you :</Typography>
            <img src={same_work_city} width="500px"/>
        </Grid>

        <Grid item>
        <Typography variant='body2'>Number of people over time that are from same city as you :</Typography>
          <img src={same_native_city_graph} width="500px"/>
        </Grid>
        

        <Grid item>
        <Typography variant='body2'>A selected set of our data, giving you a example of various parameters :</Typography>
           <img src={sample_data} width="500px"/>
        </Grid>

      </Grid>
      
      <p> The above data is ever changing. For Time series charts we do calculation every time you login. So its important to 
        keep logging in regulary for us to trigger comparision queries.
      </p>
    </div>
  );
};

export default Services;
