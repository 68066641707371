import React from 'react'
import {Table,Typography, TableBody,TableContainer,TableHead,TableRow,Paper} from '@material-ui/core';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

const PendingApproval = (props) =>{
  //console.log('inside PEnding Approval ' + JSON.stringify(props))
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.active,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.active,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.focus,
      opacity: [0.9, 0.8, 0.7],
      cursor:'pointer'
    }
  }));

    return(
    <TableContainer component={Paper}>
        <Table  stickyHeader aria-label="simple table">
          <TableHead >
            <TableRow >
              <StyledTableCell><Typography gutterBottom >User Id</Typography></StyledTableCell>
              <StyledTableCell align="right"><Typography  gutterBottom># submissions</Typography></StyledTableCell>
              <StyledTableCell align="right"><Typography gutterBottom>Submission Date</Typography></StyledTableCell>
              
              </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map(row => (
              <StyledTableRow key={row.userId} >
                <StyledTableCell component="th" scope="row" onClick={event => props.handler(event, row.userId) }>
                  {row.userId}
                </StyledTableCell>
                <StyledTableCell align="right">{row.count}</StyledTableCell>
                <StyledTableCell align="right">{row.createdAt}</StyledTableCell>
               </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>)
    
};

export default PendingApproval