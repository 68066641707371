import React, { useEffect, useState } from 'react'
import {Grid, Typography} from '@material-ui/core'

import Similarity from '../similar'
import api from '../../utils/ServiceApiSimilarity'
import axios from 'axios'
import { SERVICE_URL } from '../../config'
import ReactChart from './ReactChart'

import _ from 'lodash'
import Colours from '../../data/colours.json'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default  function TimeSeriesCharts(props){
    
    const [sameCompanyTs, setSameCompanyTs] = useState('');
    const [sameDesignationTs, setSameDesignationTs] = useState('');
    const [sameCtcTs, setSameCtcTs] = useState('');
    const [atleastOneCompanySameTs, setAtleastOneCompanySameTs] = useState('');
    const [sameNativeCityTs, setSameNativeCityTs] = useState('');
    const [higherThanHighestCtcTs, setHigherThanHighestCtcTs] = useState('');
    const [lesserThanHighestCtcTs, setLesserThanHighestCtcTs] = useState('');
    const [workedInSameCityTs, setWorkedInSameCityTs] = useState('');
    
   

    let queryParam = "?userId="+props.userId

    const getChartData = async (apiUrl, chartLabel, setFunction)=>{
        var url = SERVICE_URL+apiUrl+queryParam;
        let lineChart = {}
        //console.log('calling  url --- ' + url)
        await axios.get(url).then(response=>{
            //console.log('inside then')
            if(!_.isEmpty(response.data) ){
                //console.log('response data not null' + response.data)
                let labels = []
                let data = []
                let array = response.data
                for(let i =0; i < array.length ; i++){
                    let createdAts = new Date(array[i].createdAt).toLocaleDateString()
                    let value = array[i].value
                    // Add first and last value, also add the value if it differs from previous one
                    if(_.isEmpty(data) || data[data.length - 1] != value || i === (array.length - 1) ){
                        data.push(value)
                        labels.push(createdAts)
                    } 
                }
                lineChart["labels"] = labels;

                let dataSetTs = {}
                dataSetTs["label"] = chartLabel
                dataSetTs["data"] = data;
                dataSetTs["backgroundColor"] = Colours[22].name
               

                let dataset = []
                dataset.push(dataSetTs)

                lineChart["datasets"] = dataset   
            }
            
            //console.log('---> '+JSON.stringify(lineChart))
            setFunction(lineChart);
        }).catch(error =>{
            throw new Error(error.message)
        })
            
       
    }
    
   
        useEffect(async ()=>{
            getChartData(api.sameCompanyTs, "same company TS" , setSameCompanyTs)
        }, [])

        useEffect(async ()=>{
            getChartData(api.sameDesignationTs, "same designation TS" , setSameDesignationTs)
        }, [])

        useEffect(async ()=>{
            getChartData(api.sameCtcTs, "same ctc TS" , setSameCtcTs)
        }, [])

        useEffect(async ()=>{
            getChartData(api.atleastOneCompanySameTs, "At least one company same" , setAtleastOneCompanySameTs)
        }, [])
                
        useEffect(async ()=>{
            getChartData(api.sameNativeCityTs, "same native city" , setSameNativeCityTs)
        }, [])

        useEffect(async ()=>{
            getChartData(api.higherThanHighestCtcTs, "higher ctc" , setHigherThanHighestCtcTs)
        }, [])

        useEffect(async ()=>{
            getChartData(api.lesserThanHighestCtcTs, "lesser ctc " , setLesserThanHighestCtcTs)
        }, [])

        useEffect(async ()=>{
            getChartData(api.workedInSameCityTs, "same work city" , setWorkedInSameCityTs)
        }, [])
                
    

    return (
        <div>
            <Typography variant="overline" display="block" gutterBottom color='secondary' >Your data past and present:</Typography>
            
            <Grid container spacing={2}>
                
                <Grid item xs={12} md={6} lg={4}>
                    <ReactChart data={sameCompanyTs} type="line" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <ReactChart data={sameDesignationTs} type="line" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <ReactChart data={sameCtcTs} type="line" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <ReactChart data={atleastOneCompanySameTs} type="line" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <ReactChart data={sameNativeCityTs} type="line" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <ReactChart data={higherThanHighestCtcTs} type="line" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <ReactChart data={lesserThanHighestCtcTs} type="line" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <ReactChart data={workedInSameCityTs}  type="line" />
                </Grid>
                
            </Grid>
        </div>
    )
}
