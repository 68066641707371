import React ,{Component} from 'react'
import MyAvatar from './MyAvatar'
import Upload from './FileUpload'
import axios from 'axios'
import { Box, Typography, Container } from '@material-ui/core';
import Auth from '../utils/Auth'
import ChartDashboard from './charts/chartsDashboard';
import NotApproved from './NotApproved';
import MyAlert from './Alerts'
import { SERVICE_URL } from '../config';


class HomeDisplay extends Component{
    alert ='';
    prepareAlert = (type, message) =>{
        
    alert =  <MyAlert type={type} message={message}/>;
    
    }
    constructor(props){
        super(props)
        this.state={
            dataFiles:null,
            refresh:false,
            message:"Value",
            profileDataLoaded:false,
            docsDataLoaded:false,
            docsSubmitting:false,
            profilePicSubmitting:false,
            displayAlert:false,
            numOfFiles : null
        }
    }

    componentDidMount(props){
        
    }

    onChangeHandler =async (event) =>{
        event.preventDefault();
        //console.log('number of files uploaded '+event.target.files.length)
        this.setState({numOfFiles: event.target.files.length});
        const files = event.target.files;
        //console.log('event target name ' + event.target.name)
        const data = new FormData()
        for (var i = 0; i < files.length; i++) {
            data.append('uploadedFile', event.target.files[i]);
        }
        const user = Auth.getUserID();
        var stateObj = null;
        
        if(event.target.name ==="uploadDocs"){
           stateObj= {
                dataFiles:data,
                docsDataLoaded:true
            }
        }else {
            stateObj= {
                dataFiles:data,
                profileDataLoaded:true
            }
        }
        //console.log('user ' + user)
        data.append('userId',user)
        this.setState(stateObj)
    }
    
     onClickDPHandler=async()=>{
         this.setState({profilePicSubmitting:true})
        //console.log('onClick in HomeJS DP Handler Called')
        await axios.post(SERVICE_URL+"/users/image/profilePicture",this.state.dataFiles)
        .then(response=>{
        //console.log('success --' + response)
        //setTimeout(() => {
            this.setState({
                dataFiles:null,
                profileDataLoaded:true,
                profilePicSubmitting:false
            })
            window.location.reload(false);
        //}, 2000);
        }).catch(error=>console.log(error))
    }

    onChangeCloudinaryHandler =async (event) =>{
        event.preventDefault();
        const files = event.target.files;
        const uploadedFiles = [];
        
        for (var i = 0; i < files.length; i++) {
            uploadedFiles.push(event.target.files[i]);
        }
        
        //console.log(uploadedFiles)
        //console.log(event.target.name)
        if(event.target.name ==="uploadDocs"){
            this.setState({
                docsDataLoaded:true,
                dataFiles:uploadedFiles
             }) 
        }
    }

    onClickCloudinaryHandler=async()=>{
        this.state.docsSubmitting=true;
        
        var uploadedImageUrls = []; 
        for(var i =0; i < this.state.dataFiles.length; i++){
            const form = new FormData()
            form.append("file", this.state.dataFiles[i])
            form.append("upload_preset", "edtbqq3x")
            form.append("cloud_name","linkout")

            await axios.post("https://api.cloudinary.com/v1_1/linkout/image/upload",form)
                .then(response=>{
                    //console.log(response.data)
                    uploadedImageUrls.push(response.data.url);
                }).catch(error=>{
                    this.prepareAlert('error',error.response.data)
                    const stateObj= {
                        docsDataLoaded:false,
                        docsSubmitting:false,
                        displayAlert:true
                    }
                        
                    this.setState(stateObj)
                }).finally( ()=>{
                    
                })
           
        }

       // uploadedImageUrls =  [ 'http://res.cloudinary.com/linkout/image/upload/v1639596412/iazo5nicmbqscvcs1bcz.jpg',
     //'http://res.cloudinary.com/linkout/image/upload/v1639596412/jydj6vnyiicyocd6g59n.jpg',
     //'http://res.cloudinary.com/linkout/image/upload/v1639596413/l1n2trvany49faztbgfu.jpg' ] 

        //console.log('docs uploaded to cloudinary')
    
        if(uploadedImageUrls.length === 0){
          throw new Error('the files were not uploded to cloudinary, please check. Aborting upload flow into linkout.')  
        }
        
        //console.log('this.props.userRecord.userId ' + this.props.userRecord.id)
        await axios.post(SERVICE_URL+"/users/cloudinary/upload", {uploadedImageUrls, userId : this.props.userRecord.id})
        .then(response =>{
          //  console.log('data uploaded succesfully in internal db')
            this.prepareAlert('success',"Documents have been submitted for approval. We will notify you on approval of these documents.")
        }).catch(error =>{
            this.prepareAlert('error', error.response.data)
        }).finally(()=>{
            const stateObj= {
                docsDataLoaded:false,
                docsSubmitting:false,
                displayAlert:true
            }
            this.setState(stateObj)
        });

       // console.log('docs uploaded to internal db')
        
    }

    onClickDocHandler=async()=>{
        this.setState({
            docsSubmitting:true
        })
        //console.log('onClick in HomeJS Docs Handler Called')
        await axios.post(SERVICE_URL+"/users/upload",this.state.dataFiles)
        .then(response=>{
            this.prepareAlert('success',"Documents have been submitted for approval. We will notify you on approval of these documents.")
        }).catch(error=>{
            this.prepareAlert('error',error.response.message)
        }).finally( ()=>{
            const stateObj= {
                dataFiles:null,
                docsDataLoaded:false,
                docsSubmitting:false,
                displayAlert:true
            }
            //setTimeout(() => {
                this.setState(stateObj)
            //}, 2000);
        })
    }

    render(){

    
    
    var displayProfilePic = 
        <Box>
            
             
                <MyAvatar profilePictureUrl={this.props.userRecord.profilePicture ? this.props.profilePictureUrl : null} altText={this.props.userRecord.firstName} userName={this.props.userRecord.lastName}/>
           
          
        </Box>
    return (
         
       <Container maxWidth="lg">
           <Box>
                {this.state.displayAlert ? alert : ''}
            </Box>
            <Box>
                Welcome!
            </Box>
            <Box>
                <Typography style={{fontWeight: 500, fontSize:17}} variant="button" color="secondary">
                    {this.props.userRecord.firstName}
                    &nbsp;
                    {this.props.userRecord.middleName}
                    &nbsp;
                    {this.props.userRecord.lastName}
                </Typography>
            </Box>
            <Box>
            <Box>
                    <Upload uploadMessage="Click here:" 
                    disableProp={this.state.disableDocSave} 
                    scenario={"uploadDocs"} 
                    onChangeHandler={this.onChangeCloudinaryHandler} 
                    onClickHandler={this.onClickCloudinaryHandler} 
                    multiFile={true}
                    dataLoaded={this.state.docsDataLoaded}
                    isSubmitting={this.state.docsSubmitting}
                   />
            </Box>
            </Box>
            
            {
             
             (this.props.userRecord.status === "approved" || this.props.userRecord.status === "active") ? 
                    <ChartDashboard userRecord={this.props.userRecord} /> : <NotApproved /> }
            
       </Container>
    )
    }
}

export default HomeDisplay