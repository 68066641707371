import { CWidgetStatsF } from '@coreui/react'
import React ,{Component} from 'react'


  class WidgetImprovement extends Component{
    render(props) {
        return (
            
            <CWidgetStatsF
            className="card-mb3"
            color='#37BBE2'
            style={{ backgroundColor: '#F0F8F9' }}
            icon={this.props.icon}
            value={this.props.value  == "-1" ? "No Data" : this.props.value + (this.props.value == "1" ? ' person' : ' people')  }
            title={this.props.title}
            
            />
        )
    }
    
  }

  export default WidgetImprovement;