import * as React from 'react';
import {useForm} from "react-hook-form"
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Util from '../utils/Util';
import Alert  from '@mui/material/Alert';
import LinearProgress from '@material-ui/core/LinearProgress'


export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [submitFlag, setSubmitFlag] = React.useState(false)
  const [displayAlert, setDisplayAlert] = React.useState();
  const {register, watch, formState: { errors }, setValue} = useForm({

  })
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
        setOpen(false);
        setDisplayAlert('')
        setSubmitFlag(false)
    };

  
  const handleFormSubmit = async (data) => {
    
    let emailEntered = watch('email');
    
    if(emailEntered){
        setSubmitFlag(true)
      
        await Util.requestPasswordReset({email :emailEntered})
        
        props.showAlert()
        
        setValue('email', '')
        setSubmitFlag(false)

        setOpen(false);

    }else{
      setSubmitFlag(false)
      setDisplayAlert(<Alert severity="error">enter email id</Alert>)
    }
    
  };

  return (
    <div>
      
      <form>
      
      
      <Dialog open={open} onClose={handleClose}>
        {displayAlert}
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your email, we will send you a reset link.
          </DialogContentText>
         
          <input {...register("email", {required : true })} />
      
        </DialogContent>
        <DialogActions>
      
          <Button onClick={handleClose}>Cancel</Button>
          <Button name="submit" onClick={handleFormSubmit} type="button" disabled={submitFlag}>Send Link</Button>
          
        </DialogActions>
        { submitFlag ? <LinearProgress /> : ''}
      </Dialog>
      <Button href='#' onClick={handleClickOpen} name="Submit" >forgot password?</Button>
       
      </form>
    </div>
  );
}