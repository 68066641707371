import React, { Component } from 'react'
import Circular from './CircularIndeterminate'
import MyAlert from './Alerts'
import axios from 'axios'
import { SERVICE_URL } from '../config';
class VerificationComp extends Component{
    alert ='';
    prepareAlert = (type, message) =>{
        //console.log(type, message)
    alert =  <MyAlert type={type} message={message}/>;
    
    }

    constructor(){
        super()
        this.state={
            status:'unverified',
            displayResponse:false
        }
    }

    componentDidMount(){
        //console.log('component did mount start ' + this.props.match.params.id)
        const verificationCode = this.props.match.params.id
        if(verificationCode){
             axios.get(SERVICE_URL+"/verification/"+verificationCode)
        .then(response=>{
            //console.log(response.data)
            this.prepareAlert('info','Verification successful')
        })
        .catch(error => {
            //console.log(error)
            this.prepareAlert('warning', 'Verification invalid')
        }).finally( () =>{
            this.setState({
                displayResponse:true
             } )
        })
        }
    }

    render(){
        return(
           <div>
               {this.state.displayResponse ? alert : <Circular />}
           </div>
        )
        
    }
}


export default VerificationComp