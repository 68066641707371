import React from "react";
import LoginForm from '../Login-new_form'
import SignupLatest from '../SignupLatest'
import { AppBar, Grid, Paper, CardMedia, BottomNavigation} from "@material-ui/core";

import VerificationComp from '../Verfication'
import SetNewPassword from "../SetNewPassword";
import Container from '@mui/material/Container';

import { styled } from '@mui/material/styles';
import logo from '../../data/logo3.png'
import MyDialog from './MyDialog'

import { Route} from 'react-router-dom';



const UnauthenticatedView =  ()=>{
    

        const Item = styled(Paper)(({ theme }) => ({
            backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            ...theme.typography.body2,
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.text.secondary,
          }));

        return(
            
    <div align="left">
        
        <Route path='/verification/:id' exact component={VerificationComp}/>
        <Route path='/reset/:id' exact component={SetNewPassword}/>
        <AppBar position="relative">
            .      
        </AppBar>
        
        <Container sx={{border:0, height :'100%'}} maxWidth="md">
            <Grid container spacing={4}>
                
                <Grid item xs={8} md= {8} lg={8}>
                   <CardMedia component="img" height="194" image={logo} alt="Linkout"/>
                </Grid>                
               
                <Grid item xs={12} md={12} lg={8}>
                 
                    <Item><LoginForm /></Item>
                </Grid>
             
                <Grid item xs={12} md={12} lg={8}>
                    <Item><SignupLatest /></Item>
                </Grid>

                
            </Grid>
        </Container>
        
        <Container sx={{border:0, height :'100%'}} maxWidth="sm">
            
        <BottomNavigation>
        <Grid container>
                <Grid item><MyDialog /></Grid>
        </Grid>
        </BottomNavigation>
        </Container> 
            
        
    </div>
        )
    }


export default UnauthenticatedView;