import * as React from 'react';
import Box from '@mui/material/Box';
import {Button} from '@material-ui/core'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import about from '../footer/about'
import contact from '../footer/contact'
import legal from '../footer/legal'
import services from '../footer/services'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow:'scroll',
  height: '60%'
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState();
  const [title, setTitle] = React.useState();
  const handleOpen = (e, type) => {
    
    if(type == 'services'){
        setValue(services)
        setTitle('Our Services')
    }else if(type == 'contact'){
        setValue(contact)
        setTitle('Contact Us')
    }else if(type == 'legal'){
        setValue(legal)
        setTitle('Legal')
    }else if(type == 'aboutUs'){
        setValue(about)
        setTitle('About Us')
    }
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  return (
    <div>
      
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {value}
          </Typography>
        </Box>
      </Modal>
      
      <Button href='#' onClick={(event) => handleOpen(event, 'aboutUs')} name="Submit">About</Button>
      <Button href='#' onClick={(event) => handleOpen(event, 'contact')} name="Submit">Contact Us</Button>
      <Button href='#' onClick={(event) => handleOpen(event, 'services')} name="Submit">Services</Button>
      <Button href='#' onClick={(event) => handleOpen(event, 'legal')} name="Submit">Legal</Button>
    
    </div>
  );
}
