import React, { Component } from 'react'
import Util from '../utils/Util'
import Circular from './CircularIndeterminate'
import PendingApproval from './PendingApproval'
import Approval from './Approvals'
import {Container} from '@material-ui/core'

  
  class ApprovalComponent extends Component{
    
    constructor(){
        super()
        this.state={
            rows:[],
            displayPendingApproval : false,
            displayUpdateUser : false,
            user : null
        }
       
    }
   
    async componentDidMount(){
        await Util.getPendingApprovals();
        const rowsWithData = Util.responseData;
       
        //setTimeout(() => {
            this.setState({
                rows:rowsWithData,
                displayPendingApproval:true
            })
        //}, 1);    
    }

    setUserForUpdate=async (event, userId)=>{
        //console.log('under setUserForUpdate() in ApprovalComponent.js')
        //console.log(userId)
        await Util.getUserDetails(userId)
        const userRecord = Util.userRecord;
       this.setState({
        displayPendingApproval:false,
        displayUpdateUser:true,
        userDetails : userRecord
       })
    }

    render(){
           
         
        return (
          <Container maxWidth="lg">
                {this.state.displayPendingApproval ?
            <PendingApproval rows={this.state.rows} handler={this.setUserForUpdate}/>
             : this.state.displayUpdateUser ? <Approval user={this.state.userDetails}/> : <Circular />
                }
          </Container>
           );
           
         
    }
     
  }
    
export default ApprovalComponent