import React from 'react';

const About = () => {
  return (
    <div className="about-page">
      
       

        

      <p>At Linkout, our main aim is to help professionals take the right decision in their careers.</p>

      <p>We are a bunch of professional software engineers who always wondered where our career is going. Are we having the right skill set? How are we progressing in our career? What would it take to get into our dream company? Where are we standing in the crowd? More importantly how do we compare to others in the same domain?</p>

      <p>The answers to the above questions can be overwhelming and possibly impossible to comprehend from an individual's point of view. However, in totality things could be very  different from our own perception.This platform aims to provide that point-of-view.</p> 

      <p> We have crafted a set of statistics and have generated insights into your professional career.</p>

      <p>Look at our "Services" page to look at what all we offer. Please note, nothing on our website needs any credit card or any payment. The website use is totally free.</p>

      <p>In order to assess your profile, please provide a list of your achievements and give us an insight into your professional journey so far. We will evaluate the documents for accuracy and verify them for authenticity. This step is important to ensure that only the factual data enters our system. We aim to give a real world analysis based on the insights generated by your actual achievements.</p>

      <p>If you wish to get more clarifications and have any queries please feel free to reach out to us at the emails mentioned at "Contact Us" page.</p>

      <p>We wish you all the good luck, in your professional career. </p>

      <p>Cheers,</p>

      <p><i>Team Linkout.</i></p>

    </div>
  );
};

export default About;
