import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

export default function RecipeReviewCard() {

  return (
    <Box sx={{ width: '100%', maxWidth: 500 }}>
      <Typography paragraph>
        Thank you, for registering on Linkout.
        In order to stand out from the crowd, you have taken the first important step.
      </Typography>
      <Typography paragraph>
      We at linkout are committed to provide you with all the necessary information to drive you towards
      your goal of self-identity.
      </Typography>
      <Typography paragraph>
        To access your current achievements we are going to have a look at your skills and competencies personally.
        At Linkout our team of highly capable and hard working individuals verify the documents that you provide as a proof of your achievements.
        We accept all documents of educational, professional and personal achievements.
      </Typography>
      <Typography>
        Please use the Upload documents: <CloudUploadIcon /> section on the Home page to start your journey of self discovery.
        Since we look at all documents and approve them, we need a couple of days to completely assess your profile.
        Once your documents have been approved you will recieve a notification through email. After which you shall start
        seeing your personalized profile dashboard on the home page. 
      </Typography>
    </Box>
  );
}
