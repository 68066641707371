import React from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';

const Alert =(props) =>{
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
const MyAlert =(props)=>{ 
    //console.log(props)
    const [open, setOpen] = React.useState(true);
   return (
    
   <div>
     <Collapse in={open}>
    <Alert severity={props.type} action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
    <AlertTitle>{(props.type).toUpperCase()}</AlertTitle>
        {props.message}
        
    </Alert>
    </Collapse>
   </div>
    ) 
}

export default MyAlert