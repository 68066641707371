import React, { Fragment,Component } from "react";
import {Tab, Tabs, AppBar } from "@material-ui/core";
import { Switch, Route, Link} from "react-router-dom";

import Home from '../Home'
import Logout from '../Logout'
import Dashboard from '../Dashboard'
import VerificationComp from '../Verfication'
import ImageDisplay from "../ImageDisplay";
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';

import { Box} from '@mui/system';

class AuthenticatedView extends Component{
  
  
    componentDidMount(){

    }

    render(){
      
        var allTabs = ['/', '/logout', '/dashboard'];
        
        return(
            <div>
               
                <Route path="/" render={({ location }) => (
             <Fragment>
                <AppBar position="static">
               
                  <Box  sx={{
                    bgcolor: 'primary.main',
                }}>
                    <Tabs value={location.pathname} variant="fullWidth" textColor="primary" >
                      <Tab label="Home" value="/" style={{ color: 'unset'}} component={Link} to={allTabs[0]} icon={<HomeIcon/>} /> 
                      <Tab label="Dashboard" value="/dashboard" style={{ color: 'unset'}} component={Link} to={allTabs[2]} icon={<DashboardIcon />} /> 
                      <Tab label="Logout" value="/logout" style={{ color: 'unset'}} component={Link} to={allTabs[1]} icon={<LogoutIcon />} /> 
                    </Tabs>
                  </Box>
                 
                </AppBar>
                <Switch>
                  {<div>
                    
                  <Route path={allTabs[0]} exact component={Home}/>
                  <Route path={allTabs[1]} component={Logout} />
                  <Route path={allTabs[2]} component={Dashboard} />
                  <Route path='/verification/:id' exact component={VerificationComp}/>
                  <Route path='/image' exact component={ImageDisplay} />
                  
                  </div>
                  }
                </Switch>
              
                </Fragment>
                
            )}
            
          />
         
            </div>
        )
    }
}

export default AuthenticatedView;