import { CChart } from '@coreui/react-chartjs'
import React ,{Component} from 'react'

  class ReactChart extends Component{
    render(props) {
        return (
          <CChart
            type={this.props.type}
            data={this.props.data} 
            
            fallbackContent="loading data ... " 
            options={{
              aspectRatio: 1.5,
              elements:{
                line:{
                  fill:false,
                  stepped:false
                },
                point :{
                  pointStyle : "triangle"
                } 
              },
              tooltips: {
                enabled: false
              },
            }}
          />
        )
    }
    
  }

  export default ReactChart;