import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@material-ui/core';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width:  {
    xs: '90%', // 90% width on extra small screens
    sm: '50%',   // 400px width on small screens and up
  },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow:'scroll',
  height:  {
    xs: '50%', // 50% width on extra small screens
    sm: '50%',   // 400px width on small screens and up
  }
};

export default function BasicModal(props) {
  
  return (
    <div>
      <Grid item>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.message?.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           {props.message?.body}
          </Typography>
        </Box>
      </Modal>
      </Grid>
    </div>
  );
}
