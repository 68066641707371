import React from 'react'
import {Redirect} from 'react-router-dom'

const logout = () =>{
    window.localStorage.removeItem('token');
    window.location.reload();
    return <div>
        Logged out successfully.
        <Redirect to="/login" />

    </div>
}

export default logout;