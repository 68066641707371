import React ,{useState}from 'react'
import { useForm } from 'react-hook-form'
import {TextField, Button, LinearProgress} from '@material-ui/core'
import * as Yup from 'yup'
import Auth from '../utils/Auth'
import {useHistory} from "react-router-dom";
import MyAlert from './Alerts'
import PasswordReset from './PasswordResetRequest'
import MuiAlert from '@material-ui/lab/Alert';

 const Login = () =>{
  const Alert =(props) =>{
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
    const history = useHistory();
    const [displayAlert,setDisplayAlert] = useState('');
    const [submitFlag, setSubmitFlag] = useState(false)
    const schemaValidation = Yup.object().shape({
        email : Yup.string().required('Email is required'),
        password : Yup.string().required('Password is required').min(8,'min 8 character is needed')
      })

  const { formState: { errors } , register, handleSubmit } = useForm({
    validationSchema:schemaValidation
  })
  
  const onSubmit = async data => {
    setSubmitFlag(true)
    
    Auth.loginPromise(data)
    .then((isAdmin)=>{
     setSubmitFlag(false)
     history.push('/')
     window.location.reload()
    })
    .catch((error)=>{
      setSubmitFlag(false)
      setDisplayAlert('')
      Auth.isSessionActive=false;
      setDisplayAlert(<MyAlert type={'error'} message={error}/>)
    })
   }

   const alertHandler = ()=>{
    setDisplayAlert( <Alert severity="info">password reset request received</Alert>)
   }

  return (
    <div>
    
    {displayAlert}   
    
       
        <form onSubmit={handleSubmit(onSubmit)}>
          <div><TextField error={!!errors.email} type="text" name="email" placeholder="email"  
                    {...register('email', { required: true})} size="medium" variant="standard" fullWidth margin="normal"/>
          
        </div>
      {errors.email && errors.email.type === "required" && <span>email is required</span>}  
            <div><TextField error={!!errors.password}  type="password" name="password" 
            placeholder="password" {...register('password', { required: true}) } size="medium" variant="standard" fullWidth margin="normal"/>
            </div>
            
            {errors.password && errors.password.type === "required" && <span >password is required</span>}
            { submitFlag ? <div><Alert severity="info">Hang tight! First request may take some time.</Alert> <LinearProgress /> </div>: ''}
          <div><Button  variant="contained" color="primary" type="submit" disabled={submitFlag} name="Submit">Log in </Button>
          <div>
                  <PasswordReset showAlert={alertHandler}/>  
          </div>
          </div>
          
        </form>
    
    </div>
  );
}

export default Login