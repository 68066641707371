import React from 'react'
import Auth from '../../utils/Auth';
import AuthenticatedView from './AuthenticatedView'
import UnauthenticatedView from './UnauthenticatedView'
import AdminView from './AdminView'

const Navigation = () =>{
  //console.log("isAuthenticated called -- - " )
   const authObj = Auth.isAuthenticated()
   //console.log(authObj)
   //console.log("isAuthenticated -- - " + authObj.isSessionActive + " IsAdmin ? " + authObj.isAdmin)
   return (
    authObj.isSessionActive ? authObj.isAdmin ? <AdminView /> : <AuthenticatedView /> : <UnauthenticatedView />
   )
}



export default Navigation