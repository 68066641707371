import React, { Component } from 'react'
import Navigation from './components/navigation/NavigationCheck'
import {BrowserRouter} from 'react-router-dom'
import AlertsDisplay from './components/AlertsDisplay-old';
import SimpleReactLightbox from "simple-react-lightbox";
import { createTheme, ThemeProvider } from '@mui/material/styles'


class App extends Component {

 
   

  render(){ 
    //Color theme used is here.
    //https://material.io/resources/color/#!/?view.left=0&view.right=0&secondary.color=F44336&primary.color=303F9F
    const theme = createTheme({ 
      palette: {
          primary: {
          main: '#283593',
          dark :'#001970',
          light: '#5f5fc4'
          },
          secondary: {
          main: '#880e4f',
          dark:'#560027',
          light:'#bc477b'

          },
          text:{
            primary:{
              main: '#fff8e1',
              dark:'#bdbdbd',
              light:'#fafafa'
            },
            secondary:{
              main: '#fafafa',
              dark:'#212121',
              light:'#fff9c4'
            }
            
            
          }
      },
      });
    return (
      
      <BrowserRouter>
      <div className="App"> 
       <SimpleReactLightbox>
          <AlertsDisplay/> 
          <Navigation/>
        </SimpleReactLightbox>
     
      </div>
      </BrowserRouter>
      
    );
  }
}

export default App;
