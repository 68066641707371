import React, { Fragment,Component } from "react";
import {Tab, Tabs ,AppBar } from "@material-ui/core";
import { Switch, Route, Link} from "react-router-dom";
import Logout from '../Logout'
import Approvals from "../Approvals";
import ApprovalComponent from "../ApprovalComponent";
import LogoutIcon from '@mui/icons-material/Logout';
import { Box} from '@mui/system';
class AdminView extends Component{
    

    render(){
        var allTabs = ['/', '/logout'];
        return (
        
            <div>
           
            <Route
        path="/"
        render={({ location }) => (
          
         <Fragment>
          
            <AppBar position="static">
            <Box  sx={{
                    bgcolor: 'primary.main',
                }}>
                <Tabs value={location.pathname} variant="fullWidth">
                  <Tab label="Logout" value="/logout" component={Link} to={allTabs[1]} icon={<LogoutIcon />} style={{ color: 'unset'}}/> 
                </Tabs>
               </Box>
            </AppBar>
            <Switch>
              {<div>
                <Route path={allTabs[1]} component={Logout} />
               <Route path='/approvals' exact component={Approvals} />
               <Route path='/' exact component={ApprovalComponent} />
              
              </div>
              }
            </Switch>
          
            </Fragment>
        )}
        
      />
        </div>
           )
    }
}
    

export default AdminView