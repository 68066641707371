
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {Button} from '@material-ui/core'
import _ from 'lodash'
const filter = createFilterOptions();

export default function FreeSoloCreateOption(props) {
  const [value, setValue] = React.useState(null);

  return (
    <div>
    <Autocomplete
      value={props.comingFrom ? props.someProp  : value }
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            name: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          props.comingFrom ? props.handler_new( {name: newValue.inputValue}): setValue({
            name: newValue.inputValue,
          })  ;
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={props.listValues}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label={props.label} />
      )}
    />
    <div>
      <Button color="secondary" variant="text" disabled={false} name="Submit" onClick={()=>{
        if(!_.isEmpty(value)){
           props.comingFrom ? props.handler_new(value) : props.handler(value) 
        }
        }} >{props.name === "nTargetCompanies" ? 'Select' : 'Add'}</Button>
    <Button color="secondary" variant="text" disabled={false} name="Submit" onClick={()=>{
      
      props.comingFrom ? props.handler_new(null) : props.handler(null) 
      
    }} >{props.name === "nTargetCompanies" ? 'Clear' : 'Clear All'}</Button>

    </div>
    </div>
  );

  
}

