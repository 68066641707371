import React from 'react'
import {Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'

export default function InputSlider(props) {

    return(
            <Controller render={({ field }) => (<TextField type={props.type ? props.type : "input"} 
            fullWidth={true} disabled={props.disabled ? true : false} {...field} label={props.label} /> )} 
            name={props.name} control={props.control}  {...props} />
        )
    }
    