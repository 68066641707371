import axios from 'axios'
import jsonwebtoken from 'jsonwebtoken'
import {SERVICE_URL} from '../config'



 const Auth =  {
    
    isAdmin:false,
    isSessionActive : false,
     loginPromise : (values)=>{
        return (
            new Promise(
                function(resolve, reject){
                    async function loginUser(values){
                        await axios.post(SERVICE_URL+"/users/login/", values)
                        .then(response=>{
                            try{
                                window.localStorage.removeItem('token')
                                window.localStorage.setItem('token',response.data.token.jwt)
                            }catch(error){
                                 throw new Error(error)
                             }
                            resolve()
                        })
                        .catch(error=>{
                            reject('Unable to login.')
                        })
                    };
                    loginUser(values)
                }
            )
            )
    },
    async authenticate (values) {
    //console.log('Auth is called')
   
             //Submit form values to linkoutApis
             await axios.post(+SERVICE_URL+"/users/login/", values)
             .then( 
               response => {
                 try{
                    window.localStorage.removeItem('token')
                    window.localStorage.setItem('token',response.data.token)
                    this.isSessionActive=true;
                    if((response.data.Role).length!==0){
                        this.isAdmin=true;
                    }
                    //console.log('this.isSessionActive? ' + this.isSessionActive)
                }catch(error){
                     //console.log('local storage problem')
                     console.log(error)
                 }
              //   resetForm()
                 //prepareAlert('success', "User has been created.")
                
               }
            )
             .catch( (error) =>{
                 console.log(error.response)
                 if(error.response.status===401){
                     //prepareAlert('error', 'Email is not verified')
                 }else{
                     //prepareAlert('error', 'Invalid credentials')
                 }
                
                 //setFieldValue('displayAlert' , true)
                 this.isSessionActive=false;
             }
              
             );
    },
    signOut(){
        this.isSessionActive=false;
    },
    isAuthenticated(){
        var token = window.localStorage.getItem('token')
        //console.log("Token -- " + token)
       try{
            var decodedToken = jsonwebtoken.verify(token,"thisismynewcourse")
            if(decodedToken.role ==='Admin'){
                this.isAdmin =true;
            }
            this.isSessionActive=true;
       
       }catch(error){
           this.isSessionActive=false;
       }
        return {isSessionActive : this.isSessionActive, isAdmin : this.isAdmin };
    },
    getUserID(){
        var token = window.localStorage.getItem('token')
        try{
            const decodedToken = jsonwebtoken.decode(token, {complete: true})
            return decodedToken.payload.userId
        }catch(error){

        }
    }
    
}

export default Auth