import React, { Component } from 'react'
import {Container, Box} from '@material-ui/core'
import Auth from '../utils/Auth';
import Util from '../utils/Util';
import CircularIndeterminate from './CircularIndeterminate'

import HomeDisplay from './HomeDisplay';
import { SERVICE_URL } from '../config';

class Home extends Component{

    constructor(props){
        super(props)
        this.state={
            userRecord : null,
            profilePictureUrl:null,
            dataFile:null
        }
    }
   

    async componentDidMount(){
        
        
       await Util.getUserDetails( Auth.getUserID());
       const user = Util.userRecord;
       
       //setTimeout(() => {
         this.setState({
            userRecord: user,
             profilePictureUrl:SERVICE_URL+"/users/image/profilePicture/"+user._id
         })
       //}, 2000);
        
       
    }

    render(){
            return(
                <div>
                <Container maxWidth="lg">
                   
                    <Box>
                        {
                             this.state.userRecord ? 
                                                    <HomeDisplay 
                                                        profilePictureUrl={this.state.profilePictureUrl} 
                                                        userRecord={this.state.userRecord} 
                                                        onClickHandler={this.onClickHandler}
                                                        onChangeHandler={this.onChangeHandler}/> 
                                                    : 
                                                    <CircularIndeterminate />
                        }
                    </Box>
                    
                </Container>
            
            </div>
            )
            
        
    }
} 

export default Home