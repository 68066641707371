import React,{Component} from 'react'
import Util from '../utils/Util'
import Circular from './CircularIndeterminate'
import { SRLWrapper } from "simple-react-lightbox"; 
import { SERVICE_URL } from '../config';
class ImageDisplay extends Component{
    
    constructor(props){
        super(props)
        this.state={
            source : null,
            title:null,
            display:false,
            imageUrl:null
        }
        
    }

     async componentDidMount(){
        const userId = this.props.userId//Auth.getUserID()
        //console.log("user id in compoenet " + userId)
       
        var imageUrl_loc;
        await Util.getAllImages(userId);
        if(Util.imageIds){
          //console.log(Util.imageIds + " Inside React component ")
            if(Array.isArray(Util.imageIds)){
            //console.log('IT is  an array of image ids ');
                var urls=[];
                for(var i=0; i < Util.imageIds.length; i++){
                    var myUrl = SERVICE_URL+"/users_image/image/"+Util.imageIds[i];
                    var imgtag = <img src={myUrl} alt="my alt" key={i} width="500"/>
                   //console.log(imgtag)
                    urls.push(imgtag);
                    //console.log(urls)
                }
                imageUrl_loc = urls;
            }
        }
        //console.log("this is image in url ")
        //console.log(JSON.stringify(imageUrl_loc))

        this.setState({
            source : "data:image/png;base64,"+ Util.image,
            title:"Some test title",
            display:true,
            imageUrl:imageUrl_loc
        })
    }

     render(props){
        return (
            <div>
                <div>
                   User Uploaded documents::: 
                </div>
                {//{`data:image/jpeg;base64,${data}`}
                    this.state.display ? <div>
                        <SRLWrapper>
                            {this.state.imageUrl}
                        </SRLWrapper>
                       
                        
                     </div>: <Circular />
                }
                
            </div>
        )
     }
        
     
    
    
}

export default ImageDisplay