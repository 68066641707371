import React, { useState , useEffect } from 'react'
import moment from 'moment'
import { useForm, Controller} from 'react-hook-form'
import { Container, GridList,Box,Divider, Button,Switch, Typography, Paper} from '@material-ui/core'

import Util from '../utils/Util';

import MyAutoComplete from '../components/reusable/MyAutoComplete'
import MyAlert from './Alerts'

import NewSlider from './NewSlider'
import MyTextField from './MyTextField'
import CloudinaryImageDisplay from './CloudinaryImageDisplay';
import _ from 'lodash';

import skillListValues from '../data/skills.json'
import cityListValues from '../data/cities.json'
import companyListValues from '../data/companies.json'

 const Approvals = (props) =>{
 
  const [displayAlert,setDisplayAlert] = useState('');
  console.log(props.user.Skills + '------')
  let [tempSkills , setTempSkills] = useState(props.user.Skills)
  let skills=[...tempSkills];
  
  let [childCompanies , setChildCompanies] = useState(null)

  let [companyCity , setCompanyCity] = useState(null)

  let [tempWorkCities , setTempWorkCities] = useState(props.user.WorkCities)
  let workCities=[...tempWorkCities];

  let [tempNativeCity , setTempNativeCity] = useState(props.user.NativeCity)
  
  let [tempCompanies , setTempCompanies] = useState(props.user.CompanyUsers)//props.user.Companies
  let companies=[...tempCompanies];
  

  
  const {control, handleSubmit , getValues, setValue} = useForm({
    defaultValues:{
      email:props.user?.Auth?.userName,
      firstName:props.user.firstName,
      lastName:props.user.lastName,
      middleName:props.user.middleName,
      isGraduate:props.user.graduationCollege!=null,
      isPostGraduate:props.user.postGraduationCollege!=null,
      isDoctorate:props.user.doctoralCollege!=null,
      age:props.user.age,
      graduationCollege:props.user.graduationCollege,
      postGraduationCollege:props.user.postGraduationCollege,
      doctoralCollege:props.user.doctoralCollege,
      isApproved:props.user.status === "approved",
      graduationMarks:props.user.graduationMarks,
      postGraduationMarks:props.user.postGraduationMarks,
      doctoralMarks:props.user.doctoralMarks,
      companyName:props.user.companyName,
      currentCTC:props.user.currentCTC,
      bonus:props.user.bonus,
      serviceStartDate:moment(props.user.serviceStartDate).format('YYYY-MM-DD'),
      serviceEndDate:moment(props.user.serviceEndDate).format('YYYY-MM-DD'),
      designation:props.user.designation,
      graduationYear:moment(props.user.graduationYear).format('YYYY-MM'),
      postGraduationYear:moment(props.user.postGraduationYear).format('YYYY-MM'),
      doctoralYear:moment(props.user.doctoralYear).format('YYYY-MM'),
      tempNativeCity : props.user.NativeCity

    }});

  const clearCompanies =()=>{
    setTempCompanies([])
  }
  
  
  const companyHandler =()=>{

    if(childCompanies != null){
      let obj = {
        name : childCompanies.name ,
        companyId: childCompanies.id, 
        designation : getValues("designation") ,
        fromDate : getValues("serviceStartDate") != null ? moment(getValues("serviceStartDate"), 'YYYY/MM/DD').format("DD MMM YYYY") : getValues("serviceStartDate"),
        toDate : getValues("serviceEndDate") != null ? moment(getValues("serviceEndDate"), 'YYYY/MM/DD').format("DD MMM YYYY") : getValues("serviceEndDate"),
      }
      let City={}
      City["id"] = companyCity.cityId
      City["name"] = companyCity.name

      let Ctcs=[] //TODO: Fix this should be an object just like City
      const ctc = {
        ctc : Number(getValues("currentCTC")),
        bonus: Number(getValues("bonus"))
      }
      //alert(JSON.stringify(ctc))
      Ctcs.push(ctc);
      obj["Ctcs"] = Ctcs;
      obj["City"] = City;

      companies.push(obj)
      setTempCompanies(companies)
      //Reset form values
      setChildCompanies(null)
      setCompanyCity(null)
      
      setValue("currentCTC",'')
      setValue("bonus",'')
      setValue("serviceStartDate", moment(props.user.serviceStartDate).format('YYYY-MM-DD'))
      setValue("serviceEndDate",moment(props.user.serviceEndDate).format('YYYY-MM-DD'))
    }else{
      alert("Company name is needed")
    }
    
   

    
  }

  const printCompanies=()=>{
    //alert(JSON.stringify(tempCompanies))
    let a = []
    for(let i in tempCompanies){
      
     a.push(<Typography variant="subtitle2" color="primary">
                  Company: {tempCompanies[i]?.name} , 
                  Designation: {tempCompanies[i]?.designation} , 
                  Start Date: {tempCompanies[i]?.fromDate},
                  End Date : {tempCompanies[i]?.toDate},
                  Ctc: {tempCompanies[i]?.Ctcs[0]?.ctc},
                  Bonus : {tempCompanies[i]?.Ctcs[0]?.bonus},
                  City: {tempCompanies[i].City?.id},
                  CityId: {tempCompanies[i].City?.name}
            </Typography> )
           
    }
    return a;
  }

 

  const skillHandler = (skill)=>{
    if(_.isEmpty(skill)){
      setTempSkills([])
    }else{
      skills.push({"name":skill.name})
      setTempSkills(skills)
    }
  }

 

  const workCityHandler = (city)=>{
    if(_.isEmpty(city)){
      setTempWorkCities([])
    }else{
      workCities.push(city)
      setTempWorkCities(workCities)
    }
  }

  const nativeCityHandler = (city)=>{
    if(_.isEmpty(city)){
      setTempNativeCity('')
    }else{
      setTempNativeCity(city)
    }
  }

  const printNativeCity=(city)=>{
    //console.log('city in printnativecity '+ JSON.stringify(city))
    let name = city?.name ? city.name : (city?.City ? city.City.name: '')
    let state = city?.state ? city.state : (city?.City ? city.City.state: '')
    return <div>
           <Typography variant="h6" color="primary">{name} , {state}</Typography> 
           </div>
  }

  const printArray = (array)=>{
    var a = []
    
    for(let i in array){
      let name = array[i].name ? array[i].name : (array[i].City? array[i].City.name : '')
      let state = array[i].state ? array[i].state : (array[i].City? array[i].City.state : '')
      a.push(<Typography variant="h6" color="primary">{name} {state ? ', '+state :state }</Typography>)
    }
     return a
  }
                        
  const onSubmit = async data => {
      
    console.log( JSON.stringify(data) )
    
      delete data.gender
      delete data.password

      data["skills"] = tempSkills;
      data["workCities"] = tempWorkCities;
      data["nativeCity"] = tempNativeCity
      data["companies"] = tempCompanies;

      console.log( JSON.stringify(data) )

      await Util.patchPromise(props.user.id, data).then(function(result){
         setDisplayAlert(<MyAlert type={'success'} message={result}/>)
       },function(error){
         setDisplayAlert(<MyAlert type={'error'} message={'Try again later. ' + error}/>)
       })
  }

 
  
  return (
    <div>
      {
        displayAlert
      }
    <Container maxWidth="md">
   
    <form onSubmit={handleSubmit(onSubmit)}>
     
            <GridList cellHeight={300} spacing={1} cols={3} className={"width: 500,height: 500,transform: 'translateZ(0)'"}>
                <div>
                  <CloudinaryImageDisplay userId={props.user.id}/>
                </div>
             </GridList>  
                 
                      <Box>
                        <MyTextField label="email" name="email" control={control} disabled/>
                        
                      </Box>
                      <Box>
                        <MyTextField label="first name" name="firstName" control={control} disabled/>
                        <MyTextField label="middle name" name="middleName" control={control} disabled/>
                        <MyTextField label="last name" name="lastName" control={control} disabled/>
                      
                      </Box>
                      
                      <Box>
                        
                        <NewSlider name="age" label="age" control={control} {...props}/>
                        
                      </Box>
                      <Divider variant="fullWidth"/>
                      <Typography variant="overline" color="secondary" gutterBottom>
                          Native city details
                      </Typography>
                        
                      <Box>
                      <MyAutoComplete  comingFrom="nativeCity" handler_new={setTempNativeCity} someProp={tempNativeCity} name="nCities" control={control} listValues={cityListValues} label="Select city here"/>
                      <Paper elevation={1} variant="outlined">
                        {
                          printNativeCity(tempNativeCity)
                        }
                       </Paper>
                      </Box>
                      <Divider variant="fullWidth"/>
                      {/* <Typography variant="overline" color="secondary" gutterBottom>
                        Graduate Details
                      </Typography>
                        
                    
                       <Box width="100%" >
                       
                       <MyTextField label="Graduate College" name="graduationCollege" control={control} />
                       
                       <NewSlider name="graduationMarks" label="graduation Marks" control={control} {...props}/>

                       <Typography variant="overline" gutterBottom>
                          Graduation year
                       </Typography>
                        <MyTextField label="Graduation Year " name="graduationYear" control={control} type="month"/>
                      
                      </Box> */}
                       <Divider variant="fullWidth"/>
                      <Typography variant="overline" color="secondary" gutterBottom>
                          Professional details
                      </Typography>
                        <Divider variant="fullWidth"/>
                      
                      <Box>
                      <MyAutoComplete  comingFrom="companies" someProp={childCompanies} handler_new={setChildCompanies}  name="companies" control={control} listValues={companyListValues} label="Select company here"/>
                      <Typography variant="h6" color="secondary" gutterBottom>{childCompanies?.name} </Typography>
                      <MyAutoComplete  comingFrom="companyCity" someProp={companyCity} handler_new={setCompanyCity}  name="cities" control={control} listValues={cityListValues} label="Select city here"/>
                      <Typography variant="h6" color="secondary" gutterBottom>{companyCity?.name} </Typography>
                          
                          <MyTextField label="Designation" name="designation" control={control} />
                          <MyTextField label="Current CTC" name="currentCTC" control={control} type="number"/>
                          <MyTextField label="Bonus" name="bonus" control={control} type="number"/>
                          

                        <Typography variant="overline" gutterBottom>
                          service start date
                        </Typography>
                      
                        <MyTextField  name="serviceStartDate" control={control} type="date" />
                        
                        <Typography variant="overline" gutterBottom>
                          service end date
                        </Typography>

                        <MyTextField name="serviceEndDate" control={control} type="date"/>
                        <Paper elevation={1} variant="outlined">
                        {
                          printCompanies(tempCompanies)
                        }
                        </Paper>

                        <Button  variant="outlined" color="primary" name="Submit" onClick={companyHandler}> Add Company </Button>
                        <Button  variant="outlined" color="primary" name="Submit" onClick={clearCompanies}> Clear All </Button>
                      </Box>

                      <Divider variant="fullWidth"/>
                      <Box>
                      <Typography variant="overline" color="secondary" gutterBottom>
                          Skill details
                      </Typography>
                      <MyAutoComplete  handler={skillHandler} name="skills" control={control} listValues={skillListValues} label="Select skills here"/>
                      <Typography variant="overline" color="secondary" gutterBottom>
                          Skills for User :  
                      </Typography> 
                      <Paper elevation={1} variant="outlined">
                      { 
                        printArray(tempSkills)
                      }
                      </Paper>
                      </Box>
                      <Divider variant="fullWidth"/>
                      <Typography variant="overline" color="secondary" gutterBottom>
                          Work city details
                      </Typography>
                        <Divider variant="fullWidth"/>
                      
                      <Box>
                      
                      <MyAutoComplete  comingFrom="workCity" handler_new={workCityHandler} someProp={tempWorkCities} name="cities" control={control} listValues={cityListValues} label="Select cities here"/>
                      <Paper elevation={1} variant="outlined">
                      {
                        printArray(tempWorkCities)
                      }
                      </Paper>
                      </Box>
                      
                    <Box>
                      <label htmlFor="isApproved">is Approved?</label>
                      <Controller render={({ field }) => (<Switch {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} /> )} name="isApproved" control={control}  {...props} /> 
                    </Box>
                    
                  <div><Button  variant="contained" color="primary" type="submit" disabled={false} name="Submit">Submit </Button></div>
            
    </form>
    
    </Container>
    
    </div>
  );
}

export default Approvals