import React from 'react';

const Legal = () => {
  return (
    <div className="legal-page">
      
      <h2>Privacy Policy</h2>
    <p>This Privacy Policy outlines the types of personal information collected, used, and shared by Linkout ("we," "our," or "us") when you visit and interact with our website. By accessing or using our website, you consent to the practices described in this Privacy Policy.</p>


    <h2>Terms and Conditions</h2>
    <p>By accessing and using, you agree to comply with and be bound by these Terms and Conditions.</p>


    <h2>Intellectual Property</h2>
    <p>All content on our website, including text, images, graphics, and logos, is protected by intellectual property laws and is the property of Linkout or its content providers.</p>
    

    <h2>Disclaimer</h2>
    <p>The information provided on our website is for general informational purposes only. We do not guarantee the accuracy, completeness, or reliability of the content. Any reliance you place on such information is at your own risk.</p>
    

    <h2>Limitation of Liability</h2>
    <p>To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly.</p>
    

    

    <footer>
        <p>&copy; 2023 Linkout. All rights reserved.</p>
    </footer>
    </div>
  );
};

export default Legal;
