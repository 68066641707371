const apiDetails = Object.freeze({

//USER SIMILARITY Charts
WorkCityAPI:'/users/similarity/workCity',
DesignationAPI:'/users/similarity/designation',
SkillAPI:'/users/similarity/skills',
NativeCityAPI:'/users/similarity/nativeCity',
CompanyAPI:'/users/similarity/company',
sameCtcAPI:'/users/similarity/sameCtc',
higherCtcAPI:'/users/similarity/higherCtc',
lesserCtcAPI:'/users/similarity/lesserCtc',
sameCurrentCompanyAPI:'/users/similarity/sameCurrentCompany',
userSalaryPercentChange : '/users/similarity/percentageCtcChange',

GraduationCollegeAPI:'/users/similarity/graduationCollege?userGradCollege=',
PostGraduationCollegeAPI:'/users/similarity/postGraduationCollege?userPostGradCollege=',
DoctoralCollegeAPI:'/users/similarity/doctoralCollege?userDoctoralCollege=',
GraduationMarksAPI:'/users/similarity/graduationMarks?userGradMarks=',
PostGraduationMarksAPI:'/users/similarity/postGraduationMarks?postGraduationMarks=',
DoctoralMarksAPI:'/users/similarity/doctoralMarks?doctoralMarks=',

WorkCountryAPI:'/users/similarity/workCountry?workCountry=',
WorkStateAPI:'/users/similarity/workState?workState=',

NativeCountryAPI:'/users/similarity/nativeCountry?nativeCountry=',
NativeStateAPI:'/users/similarity/nativeState?nativeState=',
EducationCityAPI:'/users/similarity/educationCity?educationCity=',
EducationCountryAPI:'/users/similarity/educationCountry?educationCountry=',

//Password reset
PasswordResetAPI : '/users/requestPasswordReset',
ResetPasswordAPI : '/users/passwordReset/',

//Dashboard Charts
companiesDashboardAPI :'/dashboard/companies',
designationDashboardAPI :'/dashboard/designation',
nativeCityDashboardAPI:'/dashboard/nativeCity',
workCityDashboardAPI:'/dashboard/workCity',
skillDashboardAPI:'/dashboard/skills',


//TimeSeris Charts

sameCompanyTs : '/timeSeries/getSameCompanyTS',
sameDesignationTs : '/timeSeries/getSameDesignationTS',
sameCtcTs : '/timeSeries/getSameCtcTS',
atleastOneCompanySameTs : '/timeSeries/getAtLeastOneCompanySameTS',
sameNativeCityTs : '/timeSeries/getFromSameNativeCityTS',
higherThanHighestCtcTs : '/timeSeries/getHigherThanHighestCtcTS',
lesserThanHighestCtcTs : '/timeSeries/getLesserThanHighestCtcTS',
workedInSameCityTs : '/timeSeries/getWorkedInSameCityTS',

});

export default apiDetails;