import React ,{useState}from 'react'
import { useForm } from 'react-hook-form'
import {TextField, Button, LinearProgress} from '@material-ui/core'
import * as Yup from 'yup'
import Util from '../utils/Util'
import MuiAlert from '@material-ui/lab/Alert';

import MyAlert from './Alerts'

 const SignupLatest = () =>{
  var alert ='';
  const prepareAlert = (type, message) =>{
      //console.log(type, message)
     alert =  <MyAlert type={type} message={message}/>;
  }

    const [displayAlert,setDisplayAlert] = useState('');
    const [submitFlag, setSubmitFlag] = useState(false)
    

    const { formState: { errors } , reset, register, handleSubmit } = useForm()

    const Alert =(props) =>{
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
  
  const onSubmit = async data => {
    setSubmitFlag(true)
           
    
           
           let response =  await Util.loginUser(data)
           //console.log(JSON.stringify(response))
           if(response === 'success'){
                setSubmitFlag(false);
                await reset()
                await prepareAlert('success', "User has been created. Please check your email to verify and then try signing in.")
                await setDisplayAlert(alert)    
           }else{
            //console.log('error occured')
            await prepareAlert('error', "There is a error in form submission. Email has to be in correct format and password should be minimum 7 characters ")
                setSubmitFlag(false);
                await setDisplayAlert(alert)            
                
           }
    
  }

  return (
    <div>
    
    {displayAlert}   
    
       
        <form onSubmit={handleSubmit(onSubmit)}>
            
          <div><TextField error={!!errors.firstName} type="text" name="firstName" placeholder="first Name"  
                    {...register('firstName', { required: true})} size="medium" variant="standard" fullWidth margin="normal"/>
          
          </div>{errors.firstName && errors.firstName.type === "required" && <span>First Name is required</span>}  
          

          <div><TextField error={!!errors.middleName} type="text" name="middleName" placeholder="middle Name"  
                    {...register('middleName')} size="medium" variant="standard" fullWidth margin="normal"/>
          </div> 

          <div><TextField error={!!errors.lastName} type="text" name="lastName" placeholder="last Name"  
                    {...register('lastName', { required: true})} size="medium" variant="standard" fullWidth margin="normal"/>
          
          </div>{errors.lastName && errors.lastName.type === "required" && <span>Last Name is required</span>}  
          
          
          <div><TextField error={!!errors.email} type="text" name="Email" placeholder="email"  
                    {...register('email', 
                    { required: true, 
                      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
                    })} size="medium" variant="standard" fullWidth margin="normal"/>
          
          </div>{errors.email && errors.email.type === "required" && <span>Email is required</span>}
          {errors.email && errors.email.type === "pattern" && <span>Email should be in proper format</span>}    
          
          <div><TextField error={!!errors.password} type="password" name="Password" placeholder="password"  
                    {...register('password', { required: true, minLength : 7})} size="medium" variant="standard" fullWidth margin="normal"/>
          
          </div>{errors.password && errors.password.type === "required" && <span>Password is required</span>}  
          {errors.password && errors.password.type === "minLength" && <span>Password Should be minimum 7 characters long</span>}  
          
          
            
            { submitFlag ? <div><Alert severity="info">Hang tight! First request may take some time.</Alert> <LinearProgress /> </div> : ''}
          <div><Button  variant="contained" color="primary" type="submit" disabled={submitFlag} name="Submit">Sign Up </Button>
          
          </div>
        </form>
    
    </div>
  );
}

export default SignupLatest