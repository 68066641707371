import React from 'react'
import {Grid, Typography, List, ListItem, ListItemText} from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SimilarityCharts from './similarityCharts'
import { Divider } from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Comparision from './Comparision'
const ChartDashboard =(props)=>{
    const [checked, setChecked] = React.useState(false);
    const handleChange = () => {
        setChecked((prev) => !prev);
      };
   
    
      const [checkedComparision, setCheckedComparision] = React.useState(true);
      const handleChangeComparision = () => {
        setCheckedComparision((prev) => !prev);
        };
       
    const [checkedSimilarity, setCheckedSimilarity] = React.useState(true);
    const handleChangeSimilarity = () => {
        setCheckedSimilarity((prev) => !prev);
    };
    const obj = props.userRecord;
    return(
        <div>
            {console.log(JSON.stringify(obj))}
            <div>
            <Typography  onClick={handleChange} variant='overline' color="primary">My Profile:{checked ? <ExpandLess /> : <ExpandMore />}</Typography>
            
            <Collapse in={checked} timeout="auto" unmountOnExit>
                
                <Grid container>
                        
                            <Grid item>
                                <ListItemText primary="Age" secondary={<React.Fragment> 
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        > {obj.age}
                                        </Typography></React.Fragment>} key="age"/>
                                        
                            </Grid>
                           
                           
                </Grid>
            <Grid container>
            <Grid item >
                
                            <Typography variant='h6' color='primary'>Companies</Typography>
                            {(obj.CompanyUsers).map(company =>{
                            return <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            <ListItem alignItems="flex-start" key={company.name}>
                                <ListItemText
                                    primary={company.name}
                                    secondary={
                                        <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                       CTC : {company?.Ctcs[0]?.ctc}
                                        </Typography>
                                        &nbsp;&nbsp;
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                       Bonus : {company?.Ctcs[0]?.bonus}
                                        </Typography>
                                        
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                        &nbsp; Designation : {company?.designation}
                                        </Typography>

                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                        &nbsp; City : {company?.City?.name}
                                        </Typography>

                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                        &nbsp; State : {company?.City?.state}
                                        </Typography>
                                        
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                                    <Divider variant="middle" component="li" />
                            </List>
                            })
                            }
                            </Grid>
            </Grid>
            <Grid>
            <Grid item >
                
                <Typography variant='h6' color='primary'>Skills</Typography>
                {  
                    (obj.Skills).map(skill =>{
                        return <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start" key={skill.name}>
                            <ListItemText primary={skill.name} >
            
                                </ListItemText>
                        </ListItem>
                                <Divider variant="middle" component="li" />
                        </List>
                    })
                }
            </Grid>
            </Grid>
                </Collapse>
                   <Divider variant='middle'/>                 
                </div>
                
                
                <div>
               
                    
                                                                            
                    <Collapse in={true}>
                        <SimilarityCharts userId={props.userRecord.id} 
                                            designation={props.userRecord.designation}
                                            graduationCollege={props.userRecord.graduationCollege}
                                            postGradCollege={props.userRecord.postGraduationCollege}
                                            workCity={props.userRecord.workCity}
                            />
                    </Collapse>
                    <Divider />
                </div>
                
               
                

                <div>
                    
                    <Typography  variant="overline" onClick={handleChangeComparision} color='primary'>Find your chances in your dream company: {checkedComparision ? <ExpandLess /> : <ExpandMore />}</Typography>

                    <Collapse in={checkedComparision}>
                        
                        <Comparision userId={props.userRecord.id} />
                    </Collapse>
                    <Divider />
                </div>
               
        </div>
            
    )
}

export default ChartDashboard