
import React, { Component } from 'react'

import axios from 'axios'
import { SERVICE_URL } from '../config';
import SetPasswordForm from './SetPasswordForm';
import { Alert } from '@mui/material';

class SetNewPassword extends Component{
    
     val = "some"

    constructor(){
        super()
        this.state={
            status:'unverified',
            displayResponse:false,
            val : null
        }
    }

    componentDidMount(){
        console.log('component did mount start ' + this.props.match.params.id)
        const verificationCode = this.props.match.params.id
        if(verificationCode){
             axios.get(SERVICE_URL+"/users/passwordReset/"+verificationCode)
        .then(response=>{
            console.log(' successfully validated --- ')
            console.log(response.data)
            this.setState({
                val:<SetPasswordForm open={true} id={verificationCode}/>
             } )
        })
        .catch(error => {
            //console.log(error)
            this.setState({
                val: <Alert severity="error">Invalid request</Alert>
             } )

        }).finally( () =>{
            this.setState({
                displayResponse:true
             } )
        })
        }
    }

    render(){
        return(
        this.state.val
        )
        
    }
}


export default SetNewPassword