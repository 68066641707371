import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { SRLWrapper } from 'simple-react-lightbox';
import CircularIndeterminate from './CircularIndeterminate';
import { SERVICE_URL } from '../config';


export default  function CloudinaryImageDisplay(props){
    
    const [images, setImages] = useState(<CircularIndeterminate />);
    
    useEffect(()=>{
        let userId = props.userId;
        axios.get(SERVICE_URL+`/users_image/cloudinary/${userId}`)
        .then(response=>{
          //console.log('response - users_image  cloudinary ' + JSON.stringify(response.data))
          var imageUrls = response.data;
          var elements =[];
          for(var i =0; i < imageUrls.length; i++){
            var userImgId = 'user upload '+i
            var imgTag = <img src={imageUrls[i]} alt={userImgId} key={i} width="500"/>
              elements.push(imgTag)
          }

          //console.log(elements)
          setImages(elements);     
        })
       .catch(error => {
           throw new Error(error.message)
       })

   }, [])
    
   return (
    <div>
        <div>
           User Uploaded documents::: 
        </div>
        {
            <div>         
                <SRLWrapper >
                {images}
                </SRLWrapper>
            </div>
        }
        
    </div>
)

}
