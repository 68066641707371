import React, { useEffect, useState } from 'react'
import {Grid, Typography, Divider, Chip} from '@material-ui/core'

import Similarity from '../similar'
import api from '../../utils/ServiceApiSimilarity'
import axios from 'axios'
import { SERVICE_URL } from '../../config'
import ReactChart from './ReactChart'
import _ from 'lodash'
import Colours from '../../data/colours.json'
import EngineeringIcon from '@mui/icons-material/Engineering';
import WidgetImprovement from './CWidget'
import { Business } from '@material-ui/icons'

import { FcAddDatabase } from "react-icons/fc";
import { FaPeopleGroup } from "react-icons/fa6";
import { SiAwsorganizations } from "react-icons/si";

import { BiSolidCity } from "react-icons/bi";
import { RiMoneyRupeeCircleFill, RiMoneyRupeeCircleLine } from "react-icons/ri";




export default  function SimilarityChart(props){

    let rupee = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumSignificantDigits: 3,
    });
    
    const [designation, setDesignation] = useState('');
    const [workCity, setWorkCity] = useState('');
    const [nativeCity, setNativeCity] = useState('');
    const [skills, setSkills] = useState('');
    const [companies, setCompanies] = useState('');
    const [currentCompany, setCurrentCompany] = useState('');
    const [sameCtc, setSameCtc] = useState('');
    const [lesserCtc, setLesserCtc] = useState('');
    const [higherCtc, setHigherCtc] = useState('');
    const [percentChange, setPercentChange] = useState('')
    const [absoluteChange, setAbsoluteChange] = useState('')

    const getDirectValueFunction = async (URL) =>{
        /* Gets value from this kind of response
        {
            "count": 1
        }
        */ 
        var toReturn = await axios.get(URL).then(response=>{
            var value= -1;
          //  console.log('response data ' + JSON.stringify(response.data))
            if(response.data?.count !== 0 ){
                value = response.data.count;
            }
            return value;
        }).catch(error =>{
            throw new Error(error.message)
        })

        return toReturn;
        
    }

    let queryParam = "?userId="+props.userId

    useEffect( async ()=>{
        var url = SERVICE_URL+api.WorkCityAPI+queryParam;
        var value = await getDirectValueFunction(url)           
       
        setWorkCity(value);
        }, [])
    

    useEffect(async ()=>{
        var url = SERVICE_URL+api.DesignationAPI+queryParam;
        var value = await getDirectValueFunction(url)   
       
        setDesignation(value);
        }, [])

    useEffect(async ()=>{
        var url = SERVICE_URL+api.NativeCityAPI+queryParam;
        var value = await getDirectValueFunction(url)   
        
        setNativeCity(value);
        }, [])

    useEffect(async ()=>{
        var url = SERVICE_URL+api.CompanyAPI+queryParam;
        var value = await getDirectValueFunction(url)   
        
        setCompanies(value);
        }, [])

    useEffect(async ()=>{
        var url = SERVICE_URL+api.SkillAPI+queryParam;
        var value = await getDirectValueFunction(url)   
           
        setSkills(value);
        }, [])

    useEffect(async ()=>{
        var url = SERVICE_URL+api.sameCtcAPI+queryParam;
        var value = await getDirectValueFunction(url)   
            
        setSameCtc(value);
        }, [])
    useEffect(async ()=>{
        var url = SERVICE_URL+api.higherCtcAPI+queryParam;
        var value = await getDirectValueFunction(url)   
            
        setHigherCtc(value);
        }, [])
    useEffect(async ()=>{
        var url = SERVICE_URL+api.lesserCtcAPI+queryParam;
        var value = await getDirectValueFunction(url)   
            
        setLesserCtc(value);
        }, [])
    useEffect(async ()=>{
        var url = SERVICE_URL+api.sameCurrentCompanyAPI+queryParam;
        var value = await getDirectValueFunction(url)   
            
        setCurrentCompany(value);
        
        }, [])
   
        useEffect(async ()=>{
                var url = SERVICE_URL+api.userSalaryPercentChange+queryParam;
                let lineChart = {}
                await axios.get(url).then(response=>{
                    if(!_.isEmpty(response.data) ){
                        
                        let labels = []
                        let dataPercent = []
                        let array = response.data
                        

                        for(let i in array){
                            let ctc = array[i].ctc
                            let percent_change = array[i].percent_change == null ? 0.00 : parseFloat(array[i].percent_change)
                            labels.push(rupee.format(ctc))
                            dataPercent.push(percent_change)
                        }
                        lineChart["labels"] = labels;

                        let dataSetPercent = {}
                        dataSetPercent["label"] = "CTC Percent Change"
                        dataSetPercent["data"] = dataPercent;
                        dataSetPercent["backgroundColor"] = Colours[22].name
                       

                        let dataset = []
                        dataset.push(dataSetPercent)

                        lineChart["datasets"] = dataset   
                    }
                    
                    //console.log('---> '+JSON.stringify(lineChart))
                    setPercentChange(lineChart);
                }).catch(error =>{
                    throw new Error(error.message)
                })
                    
               
                }, [])

                useEffect(async ()=>{
                    var url = SERVICE_URL+api.userSalaryPercentChange+queryParam;
                    let lineChart = {}
                    await axios.get(url).then(response=>{
                        if(!_.isEmpty(response.data) ){
                            
                            let labels = []
                            let dataAbs = []
                            let array = response.data
                            for(let i in array){
                                let ctc = array[i].ctc
                                let abs_change = array[i].abs_change == null ? 0 : array[i].abs_change
                                labels.push(rupee.format(ctc))
                                dataAbs.push(abs_change)
                            }
                            lineChart["labels"] = labels;
                            let dataSetAbs = {}
                            
                            dataSetAbs["label"] = "CTC Absolute Change"
                            dataSetAbs["data"] = dataAbs;
                            dataSetAbs["backgroundColor"] = Colours[22].name;
                            
                            let dataset = []
                            dataset.push(dataSetAbs)
                            lineChart["datasets"] = dataset
                        }
                        setAbsoluteChange(lineChart);
                    }).catch(error =>{
                        throw new Error(error.message)
                    })
                        
                   
                    }, [])
    

    return (
        <div>
             
            <Grid container spacing={1}>
                <Grid container>
                    <Typography variant="overline" display="block" color="primary">How you compare with other users:</Typography>
                </Grid>
                <Typography variant="overline" display="block" gutterBottom>Based on the cities:</Typography>
                <Grid container spacing={1}>
                <Divider variant="middle" />
                    <Grid item xs={12} md={3} lg={3}>
                        
                        <WidgetImprovement title= {workCity == "1" ? "has worked in the same city" : "have worked in the same city"} value={workCity} icon={<BiSolidCity color="grey" fontSize="50px" />}/>
                    
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                    
                        <WidgetImprovement title={nativeCity == "1" ? "is from same native city" : "are from same native city"}  value={nativeCity} icon={<BiSolidCity color="grey" fontSize="50px" />}/>
                    
                    </Grid>
                </Grid>
                <Divider/>
                <Typography variant="overline" display="block" gutterBottom>Based on the skills and designation:</Typography>
                <Grid container spacing={2}>
                <Divider variant="middle" />
                    <Grid item xs={12} md={3} lg={3}>
                    
                        <WidgetImprovement title={skills == "1" ? "has similar skills" : "have similar skills"}   value={skills} icon={<FaPeopleGroup color="grey" fontSize="50px"/>}/>
                            
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        
                        <WidgetImprovement title={designation == "1" ? "has same designation" : "have same designation"}  value={designation} icon={<FaPeopleGroup color="grey" fontSize="50px" />}/>
                            
                    </Grid>
                    
                </Grid>
                
                <Typography variant="overline" display="block" gutterBottom>Compared to your highest Ctc:</Typography>
                <Grid container spacing={2}>
                <Divider variant="middle" />
                    <Grid item xs={12} md={3} lg={3}>
                        
                        <WidgetImprovement title={sameCtc =="1" ? "has Same Ctc" : "have Same Ctc"}  value={sameCtc} icon={<RiMoneyRupeeCircleLine  color="grey" fontSize='50px'/>}/>
                            
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                    
                        <WidgetImprovement title={higherCtc == "1" ? "has higher Ctc" : "have higher Ctc"}  value={higherCtc} icon={<RiMoneyRupeeCircleLine color="grey" fontSize='50px'/>}/>
                        
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        
                        <WidgetImprovement title={lesserCtc == "1" ? "has lesser Ctc" : "have lesser Ctc"}  value={lesserCtc} icon={<RiMoneyRupeeCircleLine  color="grey" fontSize='50px'/>}/>

                    </Grid>
                </Grid>
                
                <Typography variant="overline" display="block" gutterBottom>Based on your companies:</Typography>
                <Grid container spacing={2}>
                <Divider variant="middle" />
                
                    <Grid item xs={12} md={3} lg={3}>
                    
                    <WidgetImprovement title={companies =="1" ? "has at least one company same" : "have at least one company same"}  value={companies} icon={<SiAwsorganizations color="grey" fontSize='50px'/>}/>
                        
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>

                        <WidgetImprovement title={currentCompany =="1" ? "has same current company " : "have same current company "}  value={currentCompany} icon={<SiAwsorganizations color="grey" fontSize='50px'/>}/>
                            
                    </Grid>
                </Grid>
                <Typography variant="overline" display="block" gutterBottom>Based on Ctc changes:</Typography>
                <Grid container spacing={2}>
                <Divider variant="middle" />
                    <Grid item xs={12} md={6} lg={4} >
                        
                        <ReactChart data={percentChange} type="line" />

                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        
                        <ReactChart data={absoluteChange} type="line" />

                    </Grid>
                </Grid>
                
            </Grid>
        </div>
    )
}
