
import React ,{ useEffect, useState} from 'react'
import Colours from '../data/colours.json'
import ReactChart from './charts/ReactChart'

import api from '../utils/ServiceApiSimilarity'
import { SERVICE_URL } from '../config'
import axios from 'axios'
import { Divider } from '@mui/material';
import _ from 'lodash'
import TimeSeriesCharts from '../components/charts/TimeSeriesCharts'
import {Grid, Typography, Container} from '@material-ui/core'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Auth from '../utils/Auth';
import Util from '../utils/Util';
import CircularIndeterminate from './CircularIndeterminate'


export default function Dashboard(props){

    const [companiesForDashboard, setCompaniesForDashboard] = useState([]);
    const [designationForDashboard, setDesignationForDashboard] = useState([]);
    const [nativeCityForDashboard, setNativeCityForDashboard] = useState([]);
    const [workCityForDashboard, setWorkCityForDashboard] = useState([]);
    const [skillForDashboard, setSkillForDashboard] = useState([]);
    const [checkedTs, setCheckedTs] = React.useState(true);
    const [userRecord, setUserRecord] = useState({})
    const handleChangeTs = () => {
        setCheckedTs((prev) => !prev);
      };
    
    useEffect(async () => {
        await Util.getUserDetails(await Auth.getUserID());
        setUserRecord(Util.userRecord);
    }, [])

    const setData = (type, chart)=>{
        if(type === "skills"){
            setSkillForDashboard(chart);
        }else if(type === "companies"){
            setCompaniesForDashboard(chart);
        }else if(type === "nativeCity"){
            setNativeCityForDashboard(chart);
        }else if(type === "workCity"){
            setWorkCityForDashboard(chart);
        }else if(type === "designation"){
            setDesignationForDashboard(chart);
        }
    }

    const setLabel = (type)=>{
        if(type === "skills"){
            return "skills"
        }else if(type === "companies"){
            return "companies"
        }else if(type === "nativeCity"){
            return "native cities"
        }else if(type === "workCity"){
            return "work cities"
        }else if(type === "designation"){
            return "designations"
        }
    }
    const getDataCharts = (type, url)=>{
        let chart = {}
        axios.get(url)
                .then(response=>{
                   
                        if(!_.isEmpty(response.data) ){
                            
                            let labels = []
                            let dataSkills = []
                            let colourSet = []
                            let array = response.data
                            for(let i in array){
                                let name = array[i].name
                                let count = array[i].count
                                labels.push(name)
                                dataSkills.push(count)
                                colourSet.push(Colours[54].hex)
                            }
                            chart["labels"] = labels;
                            let dataSetSkills = {}
                            dataSetSkills["label"] = setLabel(type)
                            dataSetSkills["data"] = dataSkills;
                            dataSetSkills["backgroundColor"] = colourSet;
                            let dataset = []
                            dataset.push(dataSetSkills)
                            chart["datasets"] = dataset
                        }
                       setData(type, chart)
                   

                    })
    }
    
        //Cannot call useEffect in loop.

        useEffect(()=>{
            getDataCharts ('companies', SERVICE_URL+api.companiesDashboardAPI)
            // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [])
       
        useEffect(()=>{
            getDataCharts ('designation', SERVICE_URL+api.designationDashboardAPI)
            // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [])
       
        useEffect(()=>{
            getDataCharts ('workCity', SERVICE_URL+api.workCityDashboardAPI)
            // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [])
       
        useEffect(()=>{
            getDataCharts ('nativeCity', SERVICE_URL+api.nativeCityDashboardAPI)
            // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [])
       
        useEffect(()=>{
            getDataCharts ('skills', SERVICE_URL+api.skillDashboardAPI)
            // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [])
    
        
    
   
       
    return (
        <Container maxWidth="lg">
        <div>
            <Grid>
                    <div>
                        
                        <Typography  onClick={handleChangeTs} color='primary' variant='overline'>History {checkedTs ? <ExpandLess /> : <ExpandMore />}</Typography>

                        <Collapse in={checkedTs}>
                            
                            { !_.isEmpty(userRecord) ? <TimeSeriesCharts userId={userRecord.id}/> : <CircularIndeterminate />}
                        </Collapse>
                        <Divider />
                    </div>
            </Grid>
            <Grid container>
            <Grid item xs={12} md={6} lg={4}>
                    <Typography  color='primary' variant='overline' align="">Sample Data in Linkout:</Typography>
                </Grid>
            </Grid>

        <Grid container spacing={2}>
                

            <Grid item xs={12} md={6} lg={4}>
                { !_.isEmpty(skillForDashboard) ? <ReactChart   type ='bar' data={workCityForDashboard} /> : <CircularIndeterminate color={Colours[53].name}/> }   
            </Grid>
            
            <Grid item xs={12} md={6} lg={4}>
                { !_.isEmpty(nativeCityForDashboard) ? <ReactChart   type ='bar' data={nativeCityForDashboard} /> : <CircularIndeterminate color={Colours[53].name}/> }   
            </Grid>
            
      
            <Grid item xs={12} md={6} lg={4}>
                { !_.isEmpty(skillForDashboard) ? <ReactChart   type ='bar' data={skillForDashboard} /> : <CircularIndeterminate color={Colours[53].name}/> }   
            </Grid>
            
            <Grid item xs={12} md={6} lg={4}>
                { !_.isEmpty(companiesForDashboard) ? <ReactChart   type ='bar' data={companiesForDashboard} /> : <CircularIndeterminate color={Colours[53].name}/> }   
            </Grid>
            
            <Grid item xs={12} md={6} lg={4}>
                { !_.isEmpty(designationForDashboard) ? <ReactChart   type ='bar' data={designationForDashboard} /> : <CircularIndeterminate color={Colours[53].name}/> }   
            </Grid>
            
        </Grid>
        

        </div>
        </Container>
        )
    
    
}

