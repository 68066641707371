
import axios from 'axios'
import { SERVICE_URL } from '../config'
import Auth from '../utils/Auth'


const patchPromise = (userId, data) =>{
    return (
    new Promise(
        function(resolve, reject){
            async function patchUserDetails(userId, data){
                await axios.patch(SERVICE_URL+"/users/"+userId, data)
                .then(response=>{
                    //console.log(JSON.stringify(response) +" from patch User ")
                    resolve( "User patched")
                })
                .catch(error=>{
                    reject(error.message)
                })
            };
            patchUserDetails(userId, data)
        }
    )
    )
} 

const Util =  {
        imageIds : null,
        responseData : [],
        userRecord:{},
        similarityRecord:{},
        patchPromise,
     async getImage  (user){
        //console.log('user to search images for ' + user)
        await axios.get(SERVICE_URL+"/users_image/image/"+user)
        .then(response=>{
            //console.log(JSON.stringify(response))
            //console.log('response complete')
            this.image = response.data
        })
        .catch(error=>console.log(error))
    },
    async getAllImages (user){
        await axios.get(SERVICE_URL+"/users_image/allImages/"+user)
        .then(response=>{
            //console.log(JSON.stringify(response))
            //console.log('response complete')
        this.imageIds = response.data
        })
        .catch(error=>console.log(error))
    },
    async getPendingApprovals() {
        await axios.get(SERVICE_URL+"/pendingApproval")
        .then(response=>{
             this.responseData = response.data
        })
        .catch(error=>console.log(error))
    },
    //This gets the user record to display in the approval landing page.
    async getUserDetails(userId){
        await axios.get(SERVICE_URL+"/approval?userId="+userId)
        .then(response=>{
            //console.log('getUserDetails - response from service -- ')
            //console.log(JSON.stringify(response.data))
                this.userRecord = response.data
        })
        .catch(error =>{
            console.log(error)
        })
    },
    async postUserDetails(userId){
        await axios.post(SERVICE_URL+"/users/")
        .then(response=>{
            //console.log('postUserDetails - response from service -- ')
            //console.log(JSON.stringify(response))
            this.userRecord = response.data
        })
        .catch(error =>console.log(error))
    },

     async getSimilarityRecord(userId){
        await axios.get(SERVICE_URL+"/users/similarity/designation?userDesignation=Principal")
         .then(response=>{
            //console.log('response - getSimilarityRecord ' + JSON.stringify(response.data))
           return response.data;
        })
        .catch(error => {
            throw new Error(error.message)
        })
    },
    
    async patchUserDetails(userId, data){
        await axios.patch(SERVICE_URL+"/users/"+userId, data)
        .then(response=>{
            //console.log(JSON.stringify(response) +" from patch User ")
            return "User patched";
        })
        .catch(error=>{
            throw new Error(error.message)
        })
    },

    async loginUser(values){
        //console.log('insisde loginUser in Util.js')
        let response = await axios.post(SERVICE_URL+"/users/", values)
            .then( 
              response => {
          //console.log('response received ' + JSON.stringify(response.data))
                if(response.status ===200){
            //console.log('response-- ' + JSON.stringify(response));
                }
               return 'success'
              }
           )
            .catch( (error) =>{
              //  console.log(error.response.data)
                let errorMsg  = error.response.data+''
                return errorMsg;
            }
             
            );
            return response;
    },
    async requestPasswordReset(values){
        //console.log('insisde loginUser in Util.js')
        let response = await axios.post(SERVICE_URL+'/users/requestPasswordReset', values)
            .then( 
              response => {
          //console.log('response received ' + JSON.stringify(response.data))
                if(response.status ===200){
            //console.log('response-- ' + JSON.stringify(response));
                }
               return 'success'
              }
           )
            .catch( (error) =>{
              //  console.log(error.response.data)
                let errorMsg  = error.response.data+''
                return errorMsg;
            }
             
            );
            return response;
    },
    async setNewPassword(values){
        //console.log('insisde loginUser in Util.js')
        let response = await axios.post(SERVICE_URL+'/users/updatePassword/', values)
            .then( 
              response => {
          //console.log('response received ' + JSON.stringify(response.data))
                if(response.status ===200){
            //console.log('response-- ' + JSON.stringify(response));
                }
               return 'success'
              }
           )
            .catch( (error) =>{
              //  console.log(error.response.data)
                let errorMsg  = error.response.data+''
                return errorMsg;
            }
             
            );
            return response;
    },
    async validatePasswordReset(values){
        //console.log('insisde loginUser in Util.js')
        let response = await axios.get(SERVICE_URL+'/users/passwordReset/'+values)
            .then( 
              response => {
          //console.log('response received ' + JSON.stringify(response.data))
                if(response.status ===200){
            //console.log('response-- ' + JSON.stringify(response));
                }
               return 'success'
              }
           )
            .catch( (error) =>{
              //  console.log(error.response.data)
                let errorMsg  = error.response.data+''
                return errorMsg;
            }
             
            );
            return response;
    },
    async getPrediction(userId, companyId){

    let response = await axios.post(
        SERVICE_URL+'/predictionCompany',
        {
            userId,companyId
        }
    ).then(res=> {
        return {"success": true  , "message" : res}
    })
    .catch(err=>{
        let msg = err.message
        if(msg.includes(429)){
            return {"success": false  , "message" : "Too Many requests. Limit has been reached for today. Try again tomorrow."}
        }
        return err;
    })

    return response;
    }


}

export default Util