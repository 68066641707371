import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';


const CircularIndeterminate= (props) => {
  

  return (
    <div > 
      <CircularProgress color={props.color? props.color : "primary"}/>
    </div>
  );
}

export default CircularIndeterminate