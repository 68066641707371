import React from 'react'
import {Controller } from 'react-hook-form'
import Slider from '@material-ui/core/Slider'
import { Typography } from '@material-ui/core'

export default function NewSlider(props) {

    return(
                <Controller
                    name={props.name}
                    control={props.control}
                    defaultValue={13}
                    render={({field }) => (
                        <div>
                            <Typography id="input-slider-grad" variant="overline"  gutterBottom>
                                {props.label} : {field.value}
                            </Typography>
                            <Slider
                                {...field}
                                onChange={(_, value) => {
                                field.onChange(value);
                                }}
                                valueLabelDisplay="auto"
                                max={100}
                                step={1}
                            />
                        </div>
                    )}
                    {...props}
                />
)
                    }
                    