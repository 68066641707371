import React from 'react'

const AlertsDisplay = (props) =>{
    //console.log(props.statusCode)
    //console.log(props.message)
    var classes = ''
    if(props.statusCode === 201){
        classes ='alert alert-success'
    }else if (props.statusCode === 400 |props.statusCode === 404 | props.statusCode === 500 ){
        classes ='alert alert-danger'
    }
    //console.log(classes)
    return ( 
        <div className={classes} role="alert">
        {props.message}
        </div>
    )
        
        
           
        
    
}






export default AlertsDisplay