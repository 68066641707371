import * as React from 'react';
import {useForm} from "react-hook-form"
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Util from '../utils/Util';
import Alert  from '@mui/material/Alert';
import LinearProgress from '@material-ui/core/LinearProgress'


export default function SetPassword(props) {
  const [open, setOpen] = React.useState(true);
  const [successAlert, setSuccessAlert] = React.useState('')
  const [mismatchAlert, setMismatchAlert] = React.useState('')
  const [submitFlag, setSubmitFlag] = React.useState(false)
  

  const {register, watch, formState: { errors }} = useForm({

  })
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
        setOpen(false);
    };

  
  const handleFormSubmit = async (data) => {
    setSubmitFlag(true)
    setMismatchAlert('')
    
    let password = watch('newPassword');
    let repeatPassword = watch('repeatPassword');
    if(!password || !repeatPassword){
      setMismatchAlert(<Alert severity="error">Password fields are mandatory</Alert> )
      setSubmitFlag(false)
      return
    }
    console.log(password + " === " + repeatPassword)
    if(password === repeatPassword){
     // setTimeout(async () => {
      await Util.setNewPassword({password :password, id : props.id})
      setSuccessAlert(<Alert severity="info">Password reset done </Alert> )
      setSubmitFlag(false)
      setOpen(false)
      //}, 5000);
      
    }else{
      setSubmitFlag(false)
      setMismatchAlert(<Alert severity="error">Passwords dont match</Alert> )
    }
  };

  return (
    <div>
      
      <form>
      
      {successAlert}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
        {mismatchAlert}
          
         <div>
         enter new password
          
          <TextField error={!!errors.newPassword}  type="password" name="newPassword" 
            placeholder="password" {...register('newPassword', { required: true}) } size="medium" variant="standard" fullWidth margin="normal"/>
         </div>
         <div>
         re-enter new password
          
          <TextField error={!!errors.repeatPassword}  type="password" name="repeatPassword" 
            placeholder="password" {...register('repeatPassword', { required: true}) } size="medium" variant="standard" fullWidth margin="normal"/>
         </div>
          
        </DialogContent>
        <DialogActions>
      
          <Button onClick={handleClose}>Cancel</Button>
          <Button name="submit" onClick={handleFormSubmit} type="button" disabled={submitFlag}>Submit</Button>
        </DialogActions>
        { submitFlag ? <LinearProgress /> : ''}
      </Dialog>
      
       
      </form>
    </div>
  );
}