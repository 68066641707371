
import React ,{useState} from 'react'
import _ from 'lodash';
import { useForm } from 'react-hook-form'
import CircularIndeterminate from '../CircularIndeterminate';
import MyAutoComplete from '../reusable/MyAutoComplete'
import { Box,Button,Paper,Typography } from '@material-ui/core';
import companiesListValues from '../../data/companies.json'
import { SERVICE_URL } from '../../config';
import axios from 'axios';
import Util from '../../utils/Util';
import MyAlert from '../Alerts';
import BasicModal from '../reusable/MyModal';

const Comparision = (props)=>{
    const [value, setvalue] = useState('')
    let [tempTargetCompany , setTempTargetCompany] = useState('')
    const [showLoader, setShowLoader] = useState(false)
    const [responseFromGpt, setResponseFromGpt] = useState('')
    //Modal Start
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [message, setMessage] = useState('')

    //Modal End
    const targetCompanyHandler = (company)=>{
        if(_.isEmpty(company)){
          setTempTargetCompany('')
        }else{
          setTempTargetCompany(company)
        }
      }

      const comparisionHandler = async ()=>{
        if(_.isEmpty(tempTargetCompany)){
          setResponseFromGpt('Select a company to find predictions')
        }else{
          let userId = props.userId
            setShowLoader(true)
            console.log('Sending call to GPT')
            let response = await Util.getPrediction(userId, tempTargetCompany.id);
            
            console.log(JSON.stringify(response))
            let responseData;
              if(response.success){
                responseData = response?.message?.data
              }else{
                responseData = response?.message;
              }
               
              console.log(JSON.stringify(responseData))
              setResponseFromGpt(responseData)
              setShowLoader(false)
           
              setOpen(true)
              setMessage({body: responseData , title : "Analysis for "+tempTargetCompany.name})
              
            
        
        }
          
      }
      

      const {control, handleSubmit , getValues, setValue} = useForm({
        });

        const printSelectedTargetCompany=(company)=>{

            return <div>
                   <Typography variant="h6" color="primary">{company?.name}</Typography> 
                   </div>
          }

      
        return(
            
            <Box>
            <MyAutoComplete  handler={targetCompanyHandler} name="nTargetCompanies" control={control} listValues={companiesListValues} label="Select company here"/>
            <Paper elevation={1} variant="outlined">
              {
                printSelectedTargetCompany(tempTargetCompany)
              }
             </Paper>
             <div style={{ whiteSpace: 'pre-wrap' }}>{value}</div>
             
             <Paper elevation={0}>
              <BasicModal open={open} handleOpen={handleOpen} handleClose={handleClose} message={message}/>
              {showLoader ? <CircularIndeterminate/> : ''}
            </Paper>
            <Button variant="outlined" color="primary" disabled={tempTargetCompany ? false : true} onClick={comparisionHandler}>Predict my chances</Button>
            </Box>
            
        
        )
    }


export default Comparision;