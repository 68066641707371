import React from 'react';

const Contact = () => {
  return (
    <div className="contact-page">
      
      <p>For any communication with regards to your submissions please reach out to : <a color='blue'>admin@linkout.co.in</a>, please mention your username in the email.</p>
      <p>For any communication with regards to marketing please reach out to : <a color='blue'>marketing@linkout.co.in</a></p>
    </div>
  );
};

export default Contact;
